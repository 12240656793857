<template>
    <div class="heibox">
        <van-popup v-model="show" round :close-on-click-overlay="false" >
      <div class="popup ">
        <div class="top"> 
        <div class="title">{{$t('m.qqdd')}}</div>
        </div>    
        <div class="line"></div>
        <div class="linex">
            <div class="txt" v-if="step">{{$t('m.dkndqb')}}</div>
            <div class="txt" v-else>{{$t('m.zzclsw')}}</div>
        </div>
          <div class="buttom">
        <van-button
          type="primary"
          class="btn "
        >
          {{$t('m.ddz')}}...</van-button
        >
      </div> 
      </div>
    </van-popup>
    </div>
</template>
<script>
export default {
  name: 'logding',
  props: {
    show: Boolean

  },
    data(){
    return{
        step:false
    }
    },
    methods:{
        toClose(){
            // this.show=false
            this.$emit('updata',true)
        }
    }

}
</script>
<style scoped lang="scss">
.heibox{
    z-index: 9999;
    height: 100%;
    width: 100%;
    background: #ffffffa6;
    position: fixed;
    top: 0;
    left: 0;
    .popup{
    border: 1px solid #20202080;
    background: #1F1C20;
    padding: 30px 20px;
    z-index: 9999;
    min-height: 240px;
     width: 562px;
        height: auto;
    .top{
        .title{
             width: 100%;
            font-size:42px;
            font-weight: bold;
            color: #ffffff;
            text-align: center;
        }
    }
    .linex{
      display: flex;
      align-items: center;
      margin: 16px;
    
      .txt{
        margin: 20px;
        font-size: 32px;
        color: #FFFFFF;
        line-height: 56px;
      }
    }
         .buttom{
        display: flex;
        width: 100%;    
        margin: 60px auto 20px;  
        .btn{
         background: #2A2A2A;
          color: #ABABAB;
          border: none;
          border-radius: 100px;
          height: 68px;
          width: 100%;
          font-size: 24px;
        }
         }
  }
}
</style>